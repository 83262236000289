<script setup lang="ts">
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";
import { useAttrs } from "vue";

export type TitleAs = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "div" | "span";
export type TitleSize =
    | "xxsmall"
    | "xsmall"
    | "small"
    | "regular"
    | "large"
    | "xlarge"
    | "xxlarge";
export type TitleColor =
    | "dark"
    | "light"
    | "primary"
    | "black"
    | "gray"
    | "red";
export type TitleVariant = "uppercased" | "subtitle" | "main" | "special";

type Props = {
    as?: TitleAs;
    size?: TitleSize;
    color?: TitleColor;
    variant?: TitleVariant;
};
const {
    as = "h1",
    color = "dark",
    size = "regular",
    variant = undefined,
} = defineProps<Props>();
const classes: AntlerClasses<Props> = {
    base: "font-semibold tracking-tight",
    variants: {
        size: {
            xxsmall: "text-sm font-medium",
            xsmall: "text-base leading-tight",
            small: "text-lg leading-tight",
            regular: "text-2xl",
            large: "text-3xl lg:text-4xl",
            xlarge: "text-3xl md:text-4xl lg:text-5xl",
            xxlarge: "text-5xl md:text-5xl lg:text-6xl",
        },
        color: {
            black: "text-black",
            dark: "text-slate-800",
            light: "text-white",
            primary: "text-primary",
            gray: "text-gray-400",
            red: "text-red-900",
        },
        variant: {
            uppercased: "uppercase",
            subtitle: "uppercase text-sm",
            main: "uppercase text-base",
            special: "uppercase font-special font-bold"
        },
    },
    combinedVariants: [
        {
            variant: "subtitle",
            color: "dark",
            classes: "text-gray-500",
        },
    ],
};
const attrs = useAttrs();
const { aClass } = installAntlerComponent(
    "title",
    { color, size, variant },
    classes,
    attrs,
);
</script>
<template>
    <component
        :is="as"
        :class="aClass()"
    >
        <slot />
    </component>
</template>
